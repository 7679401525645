@font-face {
  font-family: raleway;
  src: url(./fonts/Raleway-VariableFont_wght.ttf);
}

@font-face {
  font-family: quicksand;
  src: url(./fonts/Quicksand-VariableFont_wght.ttf);
}

@font-face {
  font-family: poppins;
  src: url(./fonts/Poppins-Regular.ttf);
}

:root {
  --mainYellow: #f8d54b;
  --brightYellow: #ffcc01;
  --lightYellow: #f8dd70;
  --whiteYellow: #fff6d3;
  --mainOrange: #b4934b;
  --off-white: #f2fbff;
  --blueGray: #e8eef1;
  --blueGrayAccent: #d7dde0;
  --blueGrayDark: #7d858b;
  --blueBlack: #04102c;
  --bright-blue: #124369;
  --bright-blue-focus: #205d8b;
  /* --dark-blue: rgb(56, 58, 94); */
  --dark-blue: rgb(4, 16, 44);

  --transparent-blue: rgba(4, 16, 44, 0.8);
  --transparent-white: rgba(255, 255, 255, 0.8);

  --sunset-gradient: linear-gradient(var(--mainYellow), var(--mainOrange));
  --sunset-edge: inset 0 0 10px var(--mainOrange);

  --body-font: poppins;
  --header-font: raleway;
  --sub-font: quicksand;

  --med-shadow: 0 0 10px var(--blueGrayDark);
  --med-shadow-inset: inset 0 0 10px var(--blueGrayDark);
  --glow: 0 0 5px var(--whiteYellow);

  --dark-shadow: 0 0 10px var(--blueBlack);
  --dark-shadow-inset: inset 0 0 10px var(--blueBlack);
}

* {
  margin: 0;
  padding: 0;
  position: relative;
  font-family: var(--body-font);
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  background-color: var(--blueGray);
  overflow-x: hidden;
}

a,
button {
  cursor: pointer !important;
  text-decoration: none;
  transition: 200ms;
}

/* Text */

.title {
  font-family: var(--header-font);
  font-size: 3rem;
  color: var(--blueBlack);
}

.paragraph,
.article-html p,
.article-html li {
  text-align: justify;
  white-space: pre-line;
  color: var(--blueBlack);
  line-height: 1.2rem;
}

.text-block .title,
.text-block .paragraph {
  margin-bottom: 10px;
}

.para-link {
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--blueBlack);
  display: flex;
  align-items: center;
}

/* Flex templates */

.row-between {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.row-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.row-start {
  display: flex;
  justify-content: left !important;
}

.row-right {
  width: 100%;
  display: flex;
  justify-content: right;
  flex-wrap: wrap;
}

.col-between {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.col-around {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.col-start {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Containers */

.full-page {
  max-width: 100vw;
  overflow-x: hidden;
  min-height: 100vh;
  background-color: var(--blueGray);
}

.body-block {
  width: 100vw;
  box-sizing: border-box;
  padding: 20px 7%;
  z-index: 2;
}

.body-block-special {
  width: 100vw;
  box-sizing: border-box;
  padding: 20px calc(7% - 10px);
  z-index: 2;
}

.circle {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  padding: 20px;
  /* box-shadow: 0 0 10px var(--blueBlack); */
}

.ylw-block {
  /* background-image: var(--sunset-gradient); */
  background-color: var(--mainYellow);
  box-shadow: var(--sunset-edge);
}

.gray-block {
  background-color: var(--blueGray);
}

.back-up {
  position: fixed;
  top: 20px;
  right: 40px;
  z-index: 10;
}

.clear-block {
  border-radius: 25px;
  padding: 10px;
  box-shadow: var(--dark-shadow);
  text-align: center;
  background-color: var(--transparent-white);
}

.back-up a {
  color: var(--blueBlack);
  text-decoration: none;
}

.back-up span {
  margin-right: 5px;
  display: none;
}

.back-up:hover span {
  display: block;
}

.email-progress {
  display: flex;
  align-items: center;
  position: fixed;
  top: 20px;
  left: 40px;
  z-index: 3;
}

.email-progress p {
  font-size: 1.5rem;
  font-family: var(--sub-font);
  margin-right: 5px;
}

.email-progress .vector-icon {
  height: 1.5rem;
  width: 1.5rem;
  margin: 0 0 0 10px;
}

.email-progress .vector-icon path {
  fill: var(--blueGrayDark);
}

/* Navbar */

#nav-bar {
  background-color: var(--transparent-blue);
  width: 100vw;
  align-items: center;
  z-index: 11;
  flex-wrap: nowrap;
}

#nav-bar .title {
  font-size: 2rem;
  font-weight: 700;
  color: var(--mainYellow);
  margin: 0;
}

#nav-bar a {
  color: white;
  font-weight: 500;
  font-size: 1.2rem;
  margin-left: 20px;
  text-decoration: none;
}

#nav-bar a:hover {
  color: var(--whiteYellow);
}

#nav-bar .social-links {
  padding-left: 10px;
  margin-bottom: 0;
  align-items: center;
  gap: 10px;
}

#nav-bar .social-links .vector-icon {
  height: 30px;
  width: 30px;
}

#nav-bar .social-links a {
  margin-left: 0;
}

#nav-bar .social-links .vector-icon path {
  fill: var(--transparent-white);
}

#nav-bar .social-links .vector-icon:hover path {
  fill: white;
}

.nav-media {
  flex-wrap: wrap;
}

.logo-nav {
  height: 2.5rem;
  margin-right: 10px;
}

.flexed-nav {
  background-color: var(--transparent-blue);
  position: absolute;
  left: 0;
  top: 100%;
  width: 100vw;
}

.flexed-nav a {
  margin-left: 0;
  margin-bottom: 10px;
  display: block;
  text-align: center;
}

/* Assets */

.banner-img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  object-position: 0 0;
  display: block;
}

.img-icon {
  width: 80%;
  box-sizing: border-box;
}

.inline-icon {
  width: 1.5rem;
  margin: 0 10px;
}

.vector-icon {
  height: 2.5rem;
  margin-right: 10px;
  margin-top: 10px;
  /* filter: drop-shadow(--med-shadow); */
}

.vector-icon path {
  fill: var(--blueBlack);
}

.btn {
  background-color: var(--blueGray);
  /* box-shadow: var(--dark-shadow); */
  color: var(--blueBlack);
  /* border: 1px solid var(--blueBlack); */
  border: none;
  padding: 10px 20px;
  border-radius: 10px;
  min-width: 8rem;
  font-family: var(--sub-font);
  font-size: 1.2rem;
  font-weight: bold;
  float: right;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
}

.btn:hover {
  background-color: var(--blueGrayAccent);
}

.btn-ylw {
  background-color: var(--mainYellow);
}

.btn-ylw:hover {
  /* box-shadow: var(--glow); */
  background-color: var(--lightYellow);
}

.btn > .vector-icon {
  height: 1.5rem;
  margin: 0 0 0 10px;
  display: inline;
}

.invisibutton {
  background-color: transparent;
  color: white;
  border: none;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
}

/* Home ******************************************************/

/* Header */

.header {
  min-height: calc(100vh + 100px);
  overflow-x: hidden;
  max-width: 100vw;
  background-image: url("../public/images/solar-field.jpg");
  background-size: cover;
}

.header h1.title {
  /* text-align: justify; */
  color: #fff6d3;
  text-shadow: 0 0 10px var(--blueBlack);
  font-size: 4.5rem;
}

.fade-gray {
  height: 100px;
  width: 100%;
  background-image: linear-gradient(rgba(0, 0, 0, 0), var(--blueGray));
}

.fade-gray-reverse {
  height: 100px;
  width: 100%;
  background-image: linear-gradient(var(--blueGray), rgba(0, 0, 0, 0));
}

/* Card Collection */

#card-collection {
  align-items: flex-start;
  padding: 0 calc(7% - 20px);
}

.card {
  width: 200px;
  height: 300px;
  padding: 20px;
  border-radius: 10px;
  background-color: var(--transparent-white);
  box-shadow: var(--dark-shadow);
  margin: 0 20px;
  flex-grow: 1;
}

.card:hover {
  box-shadow: var(--glow);
}

.card .circle {
  width: 150px;
  height: 150px;
  margin-bottom: 20px;
}

.card .title {
  font-size: 1.5rem;
  text-align: center;
}

/* Light Text Block */

.info-block {
  align-items: flex-start;
  padding: 20px 0;
}

.info-block > div:first-child {
  max-width: calc(100% - 390px);
}

.align-right .info-block {
  flex-direction: row-reverse;
}

/* Why Solar? */

#why-solar .title {
  margin-bottom: 20px;
}

#why-solar .circle {
  width: 150px;
  height: 150px;
  margin-bottom: 10px;
}

.reason {
  text-align: center;
  width: 300px;
  margin-bottom: 20px;
}

.reason svg {
  height: 80%;
  width: 80%;
}

/* Dark Text Block */

.dark-paragraph {
  width: 100%;
}

.dark-paragraph > img {
  position: absolute;
  right: 0;
  top: 0;
  width: 20%;
  height: 100%;
  object-fit: cover;
}

.dark-paragraph > div {
  padding-right: 20%;
  height: 100%;
  background-image: linear-gradient(
    to left,
    rgba(0, 0, 0, 0) calc(20% - 100px),
    #05071f 20%
  );
}

.dark-paragraph .text-block,
.dark-paragraph .feature-array {
  padding-right: 40px;
}

.dark-paragraph .title,
.dark-paragraph a {
  color: white;
}

.dark-paragraph .title {
  margin-top: 20px;
}

.dark-paragraph .paragraph,
.dark-paragraph li {
  color: var(--blueGray);
}

/* Offset Square */

.square-main {
  width: 350px;
  height: 350px;
}

.square-ylw {
  width: 90%;
  height: 90%;
  background-color: var(--mainYellow);
  box-shadow: var(--sunset-edge);
  position: absolute;
  bottom: 0;
  right: 0;
}

.square-img {
  width: 90%;
  height: 90%;
  box-shadow: var(--dark-shadow);
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}

/* Feature Array */

ul {
  list-style: inside;
}

.feature-array {
  align-items: flex-start;
}

.feature-block .vector-icon {
  width: 2rem;
  height: 2rem;
}

.feature-array path {
  fill: white;
}

.feature-block .title {
  font-size: 2rem;
  margin: 10px 0;
}

.feature-array > div {
  text-align: left;
}

.feature-block {
  width: 300px;
  margin-bottom: 20px;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: flex-start;
}

/* Contact */

.contact-section {
  min-height: calc(100vh + 100px);
  overflow-x: hidden;
  max-width: 100vw;
  background-image: url("../public/images/solar-garage.jpg");
  background-size: cover;
}

.contact-block {
  box-shadow: var(--dark-shadow);
  margin: 40px 0 20px;
}

.contact-block > div {
  width: 100%;
  /* border-radius: 10px; */
  /* padding: 0 10px; */
}

#contact-list {
  padding: 20px;
  padding-left: calc(5% + 20px);
  background-color: rgba(255, 255, 255, 0.8);
  max-width: 40%;
  flex-grow: 1;
}

#contact-list .para-link {
  font-size: 1.8rem;
}

#contact-form {
  padding: 20px;
  padding-right: calc(5% + 20px);
  padding-left: 40px;
  max-width: 60%;
  flex-grow: 1;
  background-color: var(--transparent-blue);
}

#contact-form .col-start {
  align-items: flex-start;
}

.contact-input {
  display: block;
  width: 100%;
  border: none;
  border-radius: 10px;
  padding: 10px;
  margin: 10px 0;
  box-shadow: var(--med-shadow-inset);
}

textarea.contact-input {
  resize: none;
  flex-grow: 1;
}

#contact-form label.title {
  color: white;
  font-size: 1.5rem;
  font-weight: 600;
  margin: 10px 10px 0 10px;
  font-family: var(--sub-font);
}

#contact-form h1.title {
  font-size: 2.5rem;
  color: white;
  margin-bottom: 10px;
}

#contact-form .btn {
  margin-top: 10px;
  margin-left: 20px;
  /* margin-bottom: 20px; */
}

.link {
  padding: 10px 0;
}

.contact-link {
  text-decoration: none;
  display: block;
  color: var(--bright-blue);
  margin-bottom: 10px;
  margin-left: 10px;
  font-family: var(--sub-font);
  font-size: 1.5rem;
  font-weight: 600;
}

.link p {
  white-space: pre-line;
}

.link .vector-icon {
  height: 40px;
  min-width: 40px;
  width: 40px;
  margin: 0;
}

.link path {
  fill: var(--bright-blue);
}

.contact-link:hover {
  color: var(--bright-blue-focus);
}

.link:hover path {
  fill: var(--bright-blue-focus);
}

.footer h1 {
  font-size: 2rem;
  font-family: var(--header-font);
  color: var(--dark-blue);
  /* text-shadow: var(--med-shadow); */
}

.footer p {
  margin-bottom: 10px;
}

.footer .vector-icon {
  margin: 0;
}

.contact-section .footer h1 {
  text-shadow: var(--glow);
}

.contact-section .footer p {
  color: var(--transparent-white);
}

.contact-section .social-links .vector-icon path {
  fill: var(--transparent-white);
}

.contact-section .social-links .vector-icon:hover path {
  fill: white;
}

.social-links {
  gap: 20px;
  margin-bottom: 10px;
}

.social-links .vector-icon {
  height: 40px;
  width: 40px;
  margin: 0;
  /* filter: drop-shadow(var(--dark-shadow)); */
}

.social-links .vector-icon path {
  fill: var(--bright-blue);
}

.social-links .vector-icon:hover path {
  fill: var(--bright-blue-focus);
}

@media (max-width: 1100px) {
  .card {
    flex-direction: row;
    justify-content: left;

    width: 100%;
    height: auto;

    margin-bottom: 20px;
  }

  .card .circle {
    margin-right: 40px;
    margin-bottom: 0;
    height: 100px;
    width: 100px;
  }

  .card .title {
    font-size: 2rem;
    text-align: left;
  }

  /* Light paragraph  */

  .info-block {
    justify-content: center;
  }

  .info-block div:first-child {
    max-width: 100%;
    margin-bottom: 20px;
  }

  .square-main {
    width: 300px;
    height: 300px;
  }

  /* Why Solar? */

  #why-solar .title {
    text-align: center;
  }

  #why-solar > div {
    justify-content: space-evenly;
  }

  /* Dark Paragraph */

  .dark-paragraph .text-block,
  .dark-paragraph .feature-array {
    padding-right: 0;
  }

  /* Contact form */

  #contact-form,
  #contact-list {
    max-width: 100%;
    padding: 20px 7%;
  }

  #contact-form {
    flex-grow: 2;
  }

  .map-img {
    width: 100%;
  }
}

@media (max-width: 800px) {
  .title {
    font-size: 2.5rem;
  }

  .header h1.title {
    font-size: 3.5rem;
  }

  .card .circle {
    margin-right: 10px;
  }

  .card .title {
    max-width: calc(100% - 130px);
  }

  /* Dark Paragraph */

  .dark-paragraph {
    background-size: 100%;
    background-position-y: calc(100% - 500px);
    padding-bottom: 200px;
  }

  .dark-paragraph > img {
    left: 0;
    top: calc(100% - 350px);
    width: 100%;
    height: 350px;
  }

  .dark-paragraph > div {
    padding-right: 7%;
    padding-bottom: calc(20% + 100px);
    height: 100%;
    background-image: linear-gradient(
      to top,
      rgba(0, 0, 0, 0) calc(15% - 100px),
      #05071f 15%
    );
  }
}

@media (max-width: 700px) {
  #why-solar > div,
  .feature-array {
    justify-content: center;
  }
}

@media (max-width: 500px) {
  /* .paragraph {
        text-align: left;
    } */

  #nav-bar {
    padding-bottom: 10px;
  }

  .header h1.title {
    font-size: 3rem;
  }

  .card {
    padding: 5px;
  }

  .card .title {
    font-size: 1.5rem;
  }

  /* Dark paragraph */

  .dark-paragraph {
    background-position-y: calc(100% - 400px);
    padding-bottom: 100px;
  }

  .dark-paragraph > div {
    padding-bottom: calc(50% + 100px);
  }

  .link a {
    font-size: 1.2rem;
  }

  .link .vector-icon {
    min-width: 2rem;
    width: 2rem;
    height: 2rem;
  }
}

/* Products */

#products-header {
  background-image: url(../public/images/field-top-view.jpg);
  min-height: 25vh;
  overflow-x: hidden;
}

.product-collection {
  padding: 20px calc(7% - 20px);
  overflow-x: hidden;
}

.product-collection > .title {
  margin-left: 20px;
  z-index: 4;
}

.product-track {
  overflow-x: scroll;
  scroll-behavior: smooth;
  padding: 0;
  margin: 0 10px;
}

.small-fade-L {
  width: 20px;
  height: 100%;
  position: absolute;
  top: 0;
  left: calc(7% - 15px);
  z-index: 3;
  background-image: linear-gradient(
    to right,
    var(--blueGray),
    rgba(0, 0, 0, 0)
  );
}

.small-fade-R {
  width: 20px;
  height: 100%;
  position: absolute;
  z-index: 3;
  top: 0;
  right: calc(7% - 15px);
  /* background-color: var(--mainYellow); */
  background-image: linear-gradient(to left, var(--blueGray), rgba(0, 0, 0, 0));
}

.product-track::-webkit-scrollbar {
  display: none;
}

.product-card {
  background-color: white;
  border-radius: 10px;
  width: 250px;
  height: 300px;
  box-shadow: var(--dark-shadow);
  margin: 20px 10px;
  flex-shrink: 0;
  transition: 200ms;
}

.product-card > div:first-child {
  height: 170px;
  width: 100%;
  border-radius: 10px 10px 0 0;
  background-color: var(--blueGrayDark);
}

.product-card img {
  object-fit: cover;
  height: 100%;
  width: 100%;
  border-radius: 10px 10px 0 0;
}

.product-card img::before {
  content: "";
  height: 170px;
  width: 100%;
  border-radius: 10px 10px 0 0;
  background-color: var(--blueGrayDark);
  position: absolute;
  z-index: 3;
  background-image: url(../public/images/panel-rising-sun.png);
  background-size: 100px;
  background-position: 75px;
  background-repeat: no-repeat;
}

.product-card > div:last-child {
  border-top: 1px solid var(--blueGrayDark);
  height: 130px;
  padding: 10px;
  transition: 200ms;
}

.product-card:hover {
  width: 260px;
  height: 310px;
  margin: 15px 5px;
}

.product-card:hover > div:last-child {
  height: 130px;
  padding-right: 20px;
}

.product-card .title {
  font-size: 1.5rem;
  font-weight: 600;
  text-decoration: none;
}

.price {
  color: var(--bright-blue);
  font-family: var(--sub-font);
  font-size: 1.2rem;
  text-align: right;
}

.round-button {
  position: absolute;
  z-index: 4;
  top: 200px;
  border: none;
  offset: 50%;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  background-color: var(--blueGray);
  box-shadow: var(--med-shadow-inset), var(--dark-shadow);
  color: var(--blueBlack);
  display: none;
  align-items: center;
  justify-content: center;
}

.round-button:disabled {
  color: var(--blueGrayDark);
  cursor: default !important;
}

.product-collection:hover .round-button {
  display: flex;
}

.next-button {
  right: calc(7% - 50px);
}

.prev-button {
  left: calc(7% - 50px);
}

/* Product Template */

#product-template {
  padding-bottom: 80px;
}

#product-template .title {
  margin-bottom: 20px;
}

#product-page #nav-bar,
#product-page .flexed-nav,
#article-template #nav-bar,
#article-template .flexed-nav {
  background-color: var(--dark-blue);
}

.product-image {
  width: 400px;
  margin: 0 0 20px 20px;
  border-radius: 10px;
  box-shadow: var(--dark-shadow);
  object-fit: cover;
  float: right;
}

#product-template .price {
  text-align: left;
  font-size: 2rem;
}

#product-template .paragraph {
  margin: 10px 0;
}

#product-template .btn {
  float: none;
  margin-bottom: 20px;
}

#product-template .feature-block {
  width: calc(100% - 420px);
}

#product-template .feature-block ul {
  white-space: pre-line;
  list-style: outside;
}

#product-template .feature-block .vector-icon {
  margin-top: 0;
  min-width: 2rem;
  min-height: 2rem;
}

#product-template .feature-block .title {
  margin-top: 20px;
}

.center-div {
  width: 100%;
  /* text-align: center; */
  display: flex;
  justify-content: center;
}

/* html rendering */

.article-html p,
.article-html h2,
.article-html ul {
  margin-bottom: 10px;
}

.article-html h2 {
  font-family: var(--header-font);
  font-size: 1.5rem;
  color: var(--blueBlack);
}

.article-html p,
.article-html ul,
.article-html span {
  font-size: 1.2rem !important;
  font-family: var(--body-font) !important;
}

.article-html img {
  max-width: 500px;
  margin: 10px auto;
  border-radius: 10px;
  box-shadow: var(--dark-shadow);
  object-fit: cover;
}

.article-html figure {
  text-align: center;
}

.article-html figcaption {
  color: var(--blueGrayDark);
  font-style: italic;
  font-size: 1.3rem;
  font-weight: 600;
  font-family: var(--sub-font);
}

/* Contact Modal */

.modal {
  position: absolute;
  background-color: var(--blueGray);
  /* margin: 40px 10% 60px; */
  top: 40px;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 80vw;
  height: calc(100vh - 100px);
  overflow-y: scroll;
  border-radius: 10px;
  box-shadow: var(--dark-shadow);
}

.modal::backdrop {
  background-color: #04102c;
  opacity: 0.3;
}

.modal::-webkit-scrollbar {
  background-color: var(--blueGrayDark);
  border-radius: 10px;
}

.modal::-webkit-scrollbar-thumb {
  background-color: var(--transparent-white);
  border-radius: 10px;
  width: 20px;
}

.modal #contact-form {
  padding: 20px 60px;
  width: 100%;
  max-width: 100%;
  background-color: var(--dark-blue);
}

.modal .invisibutton {
  position: absolute;
  top: 20px;
  right: 60px;
}

.modal .email-progress .invisibutton {
  position: relative;
  top: 0;
  right: 0;
}

.modal-phone {
  background-color: var(--blueGray);
  padding: 20px 60px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.modal-phone .title {
  margin-bottom: 10px;
  font-size: 2rem;
  color: var(--dark-blue);
}

@media (max-width: 800px) {
  #product-template .price {
    font-size: 1.5rem;
  }

  .modal {
    margin: 20px;
    width: calc(100% - 40px);
  }
  .modal #contact-form,
  .modal-phone {
    padding: 20px;
  }

  .modal #contact-form .title {
    margin-right: 30px;
  }

  .modal .invisibutton {
    right: 20px;
  }

  .modal .btn {
    display: block;
  }
}

@media (max-width: 500px) {
  .product-image {
    float: none;
    width: 100%;
    margin: 0;
    margin-bottom: 10px;
  }

  #product-template .feature-block {
    width: 100%;
  }
}

/* Articles ****************************************/

.article-preview {
  margin: 10px;
  margin-bottom: 20px;
  min-height: 200px;
  border-radius: 10px;
  box-shadow: var(--dark-shadow);
  overflow: auto;
}

.article-preview img {
  border-radius: 10px 0 0 10px;
  position: absolute;
  left: 0;
  top: 0;
  width: 250px;
  height: 100%;
  object-fit: cover;
}

.article-preview div {
  border-radius: 0 10px 10px 0;
  margin-left: 150px;
  background: linear-gradient(
    to right,
    rgba(0, 0, 0, 0),
    var(--off-white) 100px
  );
  height: 100%;
  min-height: 200px;
  padding: 20px;
  padding-left: 120px;
}

.article-preview .title {
  font-size: 2rem;
  margin-bottom: 10px;
}

.article-preview:hover div {
  background: linear-gradient(to right, rgba(0, 0, 0, 0), white 100px);
}

.article-preview .paragraph {
  white-space: normal;
  margin-right: 20px;
  text-align: left;
}

.article-preview .para-link {
  color: var(--bright-blue);
}

.article-preview:hover .title,
.article-preview:hover .para-link {
  color: var(--bright-blue-focus);
}
/* Article template */

#article-template .body-block-special {
  padding: 20px calc(7% - 20px);
}

#article-template .back-up,
#articles-preview .back-up {
  right: 20px;
}

.article-page {
  box-shadow: var(--dark-shadow);
  background-color: white;
  margin-bottom: 20px;
  border-radius: 10px;
}

.article-image {
  width: 100%;
  height: 400px;
  object-fit: cover;
  object-position: top;
}

.article-body {
  padding: 20px;
}

.sub-section {
  margin-top: 1.2rem;
}

.article-contents {
  padding: 10px;
  border: 1px solid var(--blueBlack);
  overflow: auto;
  z-index: 5;
}

.article-contents a {
  display: block;
  color: var(--blueGray);
  font-family: var(--sub-font);
  font-size: 1.2rem;
  font-weight: 600;
}

.article-contents a:hover {
  color: var(--whiteYellow);
}

.article-contents .title {
  color: var(--blueGray);
  font-size: 1.5rem;
}

.article-header {
  width: 100%;
}

.article-header > div {
  border-radius: 10px 0 0 0;
  min-height: 400px;
  padding: 20px;
  padding-right: 120px;
  background: linear-gradient(to left, transparent, var(--blueBlack) 100px);
  width: calc(100% - 600px);
  z-index: 4;
}

.article-header .title {
  color: white;
}

.header-image {
  border-radius: 0 10px 0 0;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  object-fit: cover;
  width: 700px;
}

.para-image-block {
  width: 100%;
  text-align: center;
}

.para-image {
  border-radius: 10px;
  width: 100%;
  max-width: 700px;
  margin: 20px auto 5px;
}

.image-tag {
  color: var(--blueGrayDark);
  font-weight: 600;
  font-size: 1.2rem;
  font-style: italic;
  font-family: var(--sub-font);
}

@media (max-width: 800px) {
  .article-header {
    overflow: auto;
  }

  .article-header > div {
    min-height: 0;
    padding: 20px;
    padding-top: 120px;
    background: linear-gradient(to bottom, transparent, var(--blueBlack) 100px);
    width: 100%;
    margin-top: 200px;
  }

  .header-image {
    border-radius: 10px 10px 0 0;
    left: 0;
    width: 100%;
    height: 300px;
  }
}

@media (max-width: 700px) {
  .article-preview {
    min-height: 350px;
  }

  .article-preview {
    width: 100%;
    height: 100%;
  }

  .article-preview img {
    border-radius: 10px 10px 0 0;
    width: 100%;
    height: 250px;
  }

  .article-preview div {
    border-radius: 0 0 10px 10px;
    margin-left: 0;
    margin-top: 150px;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0), white 100px);
    width: 100%;
    padding-top: 120px;
    padding-left: 20px;
  }
}

@media (max-width: 500px) {
  #articles-preview .body-block-special,
  #article-template .body-block-special {
    padding: 0 0 20px;
  }

  #article-template .body-block,
  #articles-preview .body-block {
    padding: 20px;
  }

  .article-preview {
    margin: 20px 0;
    border-radius: 0;
  }

  .article-preview div,
  .article-preview img,
  .article-page,
  .header-image {
    border-radius: 0;
  }

  .article-preview img {
    border-radius: 0;
  }

  .image-tag {
    font-size: 1rem;
  }
}
